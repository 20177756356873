/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.alert-wrapper {
  .alert-button.btn-custom{
    border-radius: 10px;
    color: #fbfbfb;
  }
  .alert-button.bg-gray {
    background: #8080809c;
  }
  .alert-button.bg-red {
    background: #dc4a4ad9;
  }
  .alert-button.bg-green {
    background: #59903cd4;
  }
}

.pro-alert .alert-wrapper{
  @media (max-width: 820px) {
    max-width:85%;
  }

  @media (min-width: 820px) {
    max-width: 50%;
  }

}
.pro-alert .alert-head{
  background-color:var( --ion-color-pro-green-shade);
  margin-bottom: 20px;

  .alert-title{
    color: var(--ion-color-light);
  }

}

.pro-alert .alert-button-group{
  margin-bottom: 10px;
  margin-left: 20px;
  justify-content: flex-start;
}
.pro-toast {
  --color:#ffffff;
  --border-radius:3px;
  --border-style:solid;
  --border-width:3px;
  --background:   var( --ion-color-pro-green-shade);
  --border-color: var( --ion-color-pro-green-shade);
  --button-color:#ffffff;
  margin-top: 8%;

  @media (max-width: 820px) {
    margin-top: 35%;
    margin-left: 5%;
    width: 90%;
  }

}
.validation-errors {
  padding-top: 5px;
  text-align: left;

  .error-message {
    color: var(--ion-color-danger);
    font-size: 1rem;
    font-weight: bold;
  }
}
